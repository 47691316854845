/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ESTeams } from '../../ratings/es-teams';
import { ESTeamBox } from './eversoul-team-box';

interface IProps {
  id: number;
  mode: string;
}

export const EsTeamEntry: React.FC<IProps> = ({ id, mode }) => {
  const team = ESTeams.find((team) => team.Id === id);

  return <>{mode === 'icon' && <ESTeamBox team={team} />}</>;
};
