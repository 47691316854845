/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { IESTeamInt } from '../../../common/model/graphql-types';
import { EversoulCharacter } from './eversoul-character';
import { Tabs, Tab } from 'react-bootstrap';

interface IProps {
  team: IESTeamInt;
}

export const ESTeamBox: React.FC<IProps> = ({ team }) => {
  return (
    <>
      <div className="box eversoul-team">
        <div className="team-header">
          <div className={`skill-icon rarity-5`}>Team {team.Id}</div>
          <div className="skill-info">
            <p className="skill-name">Team {team.Id}</p>
            <p className="skill-type">
              Mode: <strong>{team.Mode}</strong> || Formation:{' '}
              <strong>{team.Formation}</strong>
            </p>
          </div>
        </div>
        <div className="team-details">
          <Tabs defaultActiveKey="team" id={'team_' + team.Id} className="mb-3">
            <Tab eventKey="team" title="Team Info">
              <h6>Characters</h6>
              <div className="employee-container for-nikke">
                <EversoulCharacter
                  mode="icon"
                  slug={team.Pos_1}
                  enablePopover
                />
                <EversoulCharacter
                  mode="icon"
                  slug={team.Pos_2}
                  enablePopover
                />
                {team.Pos_3 != 'flex' && (
                  <EversoulCharacter
                    mode="icon"
                    slug={team.Pos_3}
                    enablePopover
                  />
                )}
                {team.Pos_4 != 'flex' && (
                  <EversoulCharacter
                    mode="icon"
                    slug={team.Pos_4}
                    enablePopover
                  />
                )}
                {team.Pos_5 != 'flex' && (
                  <EversoulCharacter
                    mode="icon"
                    slug={team.Pos_5}
                    enablePopover
                  />
                )}
              </div>
              <h6>About the team</h6>
              <p>{team.Explanation}</p>
              <h6>Substitutions & Ultimates</h6>
              <p>{team.Subs != '' ? team.Subs : '-'}</p>
              <h6>Gear & Investment</h6>
              <p>{team.Gear != '' ? team.Gear : '-'}</p>
            </Tab>
            <Tab eventKey="visual" title="Visual Formation">
              <div className="es-visual-team">
                <div className="back-row">
                  <p>Back Row</p>
                  <div className="characters-inside">
                    {team.Formation === 'Ranged' && (
                      <EversoulCharacter
                        mode="icon"
                        slug={team.Pos_2}
                        enablePopover
                      />
                    )}
                    {(team.Formation === 'Basic' ||
                      team.Formation === 'Ranged') && (
                      <>
                        {team.Pos_3 != 'flex' ? (
                          <>
                            <EversoulCharacter
                              mode="icon"
                              slug={team.Pos_3}
                              enablePopover
                            />
                          </>
                        ) : (
                          <>
                            <div className="flex">FLEX</div>
                          </>
                        )}
                      </>
                    )}
                    {team.Formation != 'Assault' && (
                      <>
                        {team.Pos_4 != 'flex' ? (
                          <>
                            <EversoulCharacter
                              mode="icon"
                              slug={team.Pos_4}
                              enablePopover
                            />
                          </>
                        ) : (
                          <>
                            <div className="flex">FLEX</div>
                          </>
                        )}
                      </>
                    )}
                    {team.Pos_5 != 'flex' ? (
                      <>
                        <EversoulCharacter
                          mode="icon"
                          slug={team.Pos_5}
                          enablePopover
                        />
                      </>
                    ) : (
                      <>
                        <div className="flex">FLEX</div>
                      </>
                    )}
                  </div>
                </div>
                <div className="front-row">
                  <p>Front Row</p>
                  <div className="characters-inside">
                    <EversoulCharacter
                      mode="icon"
                      slug={team.Pos_1}
                      enablePopover
                    />
                    {team.Formation != 'Ranged' && (
                      <EversoulCharacter
                        mode="icon"
                        slug={team.Pos_2}
                        enablePopover
                      />
                    )}
                    {(team.Formation === 'Defense' ||
                      team.Formation === 'Assault') && (
                      <>
                        {team.Pos_3 != 'flex' ? (
                          <>
                            <EversoulCharacter
                              mode="icon"
                              slug={team.Pos_3}
                              enablePopover
                            />
                          </>
                        ) : (
                          <>
                            <div className="flex">FLEX</div>
                          </>
                        )}
                      </>
                    )}
                    {team.Formation === 'Assault' && (
                      <>
                        {team.Pos_4 != 'flex' ? (
                          <>
                            <EversoulCharacter
                              mode="icon"
                              slug={team.Pos_4}
                              enablePopover
                            />
                          </>
                        ) : (
                          <>
                            <div className="flex">FLEX</div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {team.Treasure && (
                <>
                  <h6>Treasure</h6>
                  <p>{team.Treasure}</p>
                </>
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};
